/* ADD EVENT*/
//add more than one events
function add_event(elem,event,func){
	if(document.addEventListener){
		elem.addEventListener(event,func,false);
    }else{
        elem.attachEvent("on" + event,func);
    }
}
function remove_event(elem,event,func){
    if(document.addEventListener){
        elem.removeEventListener(event,func,false);
    }else{
        elem.detachEvent("on" + event,func);
    }
}



/* ANIMATE */
function animate(obj){
        var start = new Date;
        var timer = setInterval(function(){
            var progress = (new Date - start) / obj.time;//progress changes from 0 to 1

            if(progress > 1){
                progress = 1;
            }
            obj.animate_func(progress);//user animate function
            if(progress == 1){
                clearInterval(timer);
                if(obj.complete_func){//complete animate function
                    obj.complete_func();
                }
            }
        },10);
    }
	
//reverse progress, from 1 to 0
function reverse_progress(progress){
	var new_progress = 1 - progress;
	return new_progress;
}


function hideVisiblePopups(hide_overlay)
{
	// var elements = document.getElementsByClassName('super_popup');
	var elements = getElementsByClassNameIESupport(document, 'super_popup');

	for(var i = 0; i < elements.length;i++){
		if(elements[i].style.display !== "none" && elements[i].getAttribute("keep-open") == null){
			elements[i].style.display = "none";
		}
	}
	if(hide_overlay)
	{
		document.getElementById('popup_overlay').style.display = "none";
	}
}

/* POPUP */
function popup(option){
    if(!option.popup_id){
        return false;
    }
    var popup = document.getElementById(option.popup_id);
    if(!popup){
        return false;
    }
    var close_button = true;
    if(option.close_button === false){
        close_button = false;
    }
    var hide_on_click = true;
    if(option.hide_on_click === false){
        hide_on_click = false;
    }

    var first_child = popup.children[0];
    var popup_overlay = document.getElementById("popup_overlay");

    // if(!window.all_popup && document.getElementsByClassName){
    //     window.all_popup = document.getElementsByClassName("super_popup");
    // }
    if(!window.all_popup && typeof getElementsByClassNameIESupport === "function"){
        window.all_popup = getElementsByClassNameIESupport(document ,"super_popup");
    }

    // if(!window.all_popup_curent_state && document.getElementsByClassName){
    if(!window.all_popup_curent_state && typeof getElementsByClassNameIESupport === "function"){
        window.all_popup_curent_state = {};
        for(var i = 0;i < all_popup.length;i++){
            (function(a){
                add_event(all_popup[a],"webkitAnimationEnd",function(){
                    all_popup[a].className = "super_popup showed";
                });
				add_event(all_popup[a],"mozAnimationEnd",function(){
                    all_popup[a].className = "super_popup showed";
                });
				add_event(all_popup[a],"oAnimationEnd",function(){
                    all_popup[a].className = "super_popup showed";
                });
				add_event(all_popup[a],"msAnimationEnd",function(){
                    all_popup[a].className = "super_popup showed";
                });
				add_event(all_popup[a],"animationend",function(){
                    all_popup[a].className = "super_popup showed";
                });
                all_popup_curent_state[all_popup[a].id] = {
                    visible:false,
                    index:a
                };
            })(i);
        }
    }
    function normal_style(style){
        if(!style || style == "auto"){
            return 0;
        }
        return style;
    }

    function showPopup() {
        //
        hideVisiblePopups();
        //
        popup.style.position = "absolute";
        popup.style.left = "50%";
        popup.style.zIndex = 200;
        popup.style.opacity = 0;
        popup.style.filter = "alpha(opacity = 0)";
        popup.style.display = "block";
        popup.className = "super_popup showed"; //--
        resizePopup();
        if (popup_overlay) {
            popup_overlay.style.display = "block";
        }
        animate({
            time: 500 || option.showTime,
            animate_func:function(progress){
                popup.style.opacity = progress;
                popup.style.filter = "alpha(opacity = "+100 * progress+")";
            },
            complete_func:function(){
				// if(document.getElementsByClassName){
				if(typeof getElementsByClassNameIESupport === "function"){
                                        if(all_popup_curent_state[option.popup_id])
                                            all_popup_curent_state[option.popup_id].visible = true;
				}
                if(option.callback){
                    option.callback();
                }
            }
        });
    }
    function closePopup(){
        var video = document.getElementById("uloop_video");
        if(video)
            video.pause();
        
        remove_event(window,"resize",resizePopup);
        animate({
            time: 200 || option.closeTime,
            animate_func:function(progress){
                popup.style.opacity = reverse_progress(progress);
                popup.style.filter = "alpha(opacity = "+100 * reverse_progress(progress)+")";
            },
            complete_func:function(){
                popup.style.display = "none";
				popup.className = "super_popup"; //--
				// if(document.getElementsByClassName){
				if(typeof getElementsByClassNameIESupport === "function"){
                    if(all_popup_curent_state[option.popup_id])
					    all_popup_curent_state[option.popup_id].visible = false;
				}

                var there_are_open_popups = false;
                var elements = getElementsByClassNameIESupport(document, 'super_popup');
                for(var i = 0; i < elements.length;i++){
                    if(elements[i].style.display !== "none" && elements[i].style.display !== ""){
                        there_are_open_popups = true;
                    }
                }

                if(!there_are_open_popups && popup_overlay){
                    popup_overlay.style.display = "none";
                }
                if(!there_are_open_popups && hide_on_click){
                    remove_event(popup_overlay,"click",closePopup);
                }
            }
        });
    }
    function resizePopup(){
        popup.style.position = "absolute";
         
        var minTopPopup = 10;
        var win_height = window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight||0;
        var firs_child_styles = window.getComputedStyle ? getComputedStyle(first_child,"") : first_child.currentStyle;
        var popup_scroll = popup.offsetWidth - popup.scrollWidth;
        var popup_height = popup.clientHeight;
        var total_width = first_child.offsetWidth + parseInt(normal_style(firs_child_styles.marginLeft)) + parseInt(normal_style(firs_child_styles.marginRight))+popup_scroll;
        //var total_height = first_child.offsetHeight + parseInt(normal_style(firs_child_styles.marginTop)) + parseInt(normal_style(firs_child_styles.marginBottom));
        var total_height = popup.offsetHeight;
        var page_scroll = getCurrentPageScroll();
        var data_position = popup.getAttribute("data-position");
       
        if(data_position == 'fixed' && win_height > popup_height){
            var topPopup = (win_height - popup_height) / 2;
            minTopPopup = 0;
            popup.style.position = "fixed";
        }
        else if((document.documentElement.clientHeight / 2) - (total_height / 2) < 0){
            var topPopup = page_scroll.top + 10;
        }
        else{
            var topPopup = ((document.documentElement.clientHeight / 2) - (total_height / 2)) + page_scroll.top;
        }
        
        popup.style.marginLeft = -(total_width / 2) + "px";
      
        if( topPopup < minTopPopup )
            popup.style.top = minTopPopup+"px";
        else
             popup.style.top = topPopup+"px";
    }

    if(close_button){
        var last_child = popup.children[popup.children.length - 1];
        if(last_child.className !== "popup_close_button"){
            var close = document.createElement("span");
            close.className = "popup_close_button";
            add_event(close,"click",closePopup);
            popup.appendChild(close);
        }
    }

    if(hide_on_click && popup_overlay){
        add_event(popup_overlay,"click",closePopup);
    }
    add_event(window,"resize",resizePopup);
    showPopup();
}
window.popup2 = popup;
function popup_error(){
    if(window.all_popup_curent_state && window.all_popup){
        for(var key in all_popup_curent_state){
            if(all_popup_curent_state[key].visible){
                all_popup[all_popup_curent_state[key].index].className = "super_popup popup_error";
                break;
            }
        }
    }
}

function getCurrentPageScroll(){
    var left = 0;
    var top = 0;
    if(window.pageYOffset || document.documentElement.scrollTop){
        top = window.pageYOffset || document.documentElement.scrollTop;
        left = window.pageXOffset || document.documentElement.scrollLeft;
    }else{
        var html = document.documentElement;
        var body = document.body;

        var scrollTop = html.scrollTop || body && body.scrollTop || 0;
        var scrollLeft = html.scrollLeft || body && body.scrollLeft || 0;
        top = scrollTop - html.clientTop;
        left = scrollLeft - html.clientLeft;
    }
    return {
        top:top,
        left:left
    };
} 
