addScriptToQueue(() => {
    require('./src/tabs');

    require('./src/slider');

    require('./src/campus.common');

    require('./src/jquery.cookie');

    require('./src/groupinputs');

    require('./src/popup');
}, 0);
