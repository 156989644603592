/*
 *	Inputs Def Value
 */

var $ = function(id){
	return document.getElementById(id);
}

function dhtmlCampusAveLoadScript(url)
{
	var e = document.createElement("script");
	e.src = url;
	e.type="text/javascript";
	document.getElementsByTagName("head")[0].appendChild(e);
}

function CampusQuickPostAds(elm,e)
{
	// var href = elm.getAttribute('href');
	// if (typeof(href) == 'undefined' || href == null || !href.length || href == '#') {
	// 	href = AJAXBaseURL + '/post_ads.htm';
	// }

	// if( typeof(window.CodeAdapter) === 'undefined' ||window.CodeAdapter.project_type === 'uloop'){
	// 	popup
	// 	({
	// 		popup_id: "post_listing_popup",
	// 		speed: 100
	// 	});
	// 	return false;
	// }

	return CampusQuickPostAdsOriginal(elm,e);
}

function CampusQuickPostAdsOriginal(elm,e)
{
	var href = elm.getAttribute('href');
	if (typeof(href) == 'undefined' || href == null || !href.length || href == '#') {
		href = AJAXBaseURL + '/post_ads.htm';
	}

	if (elm.getAttribute('options') == 'login-required' || elm.getAttribute('options') == 'login-required-main' || elm.getAttribute('options') == 'no-login' ) {
		if ($('globalLoginRedirect'))
			$('globalLoginRedirect').value = href;
		if ($('globalRegisterRedirect'))
			$('globalRegisterRedirect').value = href;
		showLoginDialog(e, elm.getAttribute('options'), elm.getAttribute('options-type'));
		return false;
	}
	else if (elm.getAttribute('options') == 'choiseschool' && jQ('#register_user_and_choise_school input[name$="school_i"]').val() == 0) {
		if ($('globalLoginRedirect'))
			$('globalLoginRedirect').value = href;
		if ($('globalRegisterRedirect'))
			$('globalRegisterRedirect').value = href;
		showLoginDialogChoiseSchool(e);
		return false;
	}
	window.location.href = href;
	return false;
}

function CampusHideAllTopControls()
{
 var elements = document.getElementsByTagName('object');
 var elements2 = document.getElementsByTagName('embed');
 if(elements)
	for(var i=0;i<elements.length;i++)
	 {
	   elements[i].cmpsBackupVisibility = elements[i].style.visibility;
	   elements[i].style.visibility = 'hidden';
	 }
 if(elements2)
	for(var i=0;i<elements2.length;i++)
	 {
	   elements2[i].cmpsBackupVisibility = elements2[i].style.visibility;
	   elements2[i].style.visibility = 'hidden';
	 }

}

function CampusRestoreAllTopControls()
{
 var elements = document.getElementsByTagName('object');
 var elements2 = document.getElementsByTagName('embed');
 if(elements)
	 for(var i=0;i<elements.length;i++)
	 {
	   if(elements[i].cmpsBackupVisibility!=undefined)
		  elements[i].style.visibility = elements[i].cmpsBackupVisibility;
	 }
 if(elements2)
	 for(var i=0;i<elements2.length;i++)
	 {
	   if(elements2[i].cmpsBackupVisibility!=undefined)
		  elements2[i].style.visibility = elements2[i].cmpsBackupVisibility;

	 }
}

function getElmsByClass(oElm, strTagName, strClassName){
	var arrElements = (strTagName == "*" && oElm.all)? oElm.all :
	oElm.getElementsByTagName(strTagName);
	var arrReturnElements = new Array();
	strClassName = strClassName.replace(/\-/g, "\\-");
	var oRegExp = new RegExp("(^|\\s)" + strClassName + "(\\s|$)");
	var oElement;
	for(var i=0; i<arrElements.length; i++){
		oElement = arrElements[i];
		if(oRegExp.test(oElement.className)){
			arrReturnElements.push(oElement);
		}
	}
	return (arrReturnElements)
}


function showCustomModalDialog(width,height,html,showCloseButton)
{
	var win = CMPS_GetWindowSize();
	showCloseButton = showCloseButton || true;
	var popup = document.getElementById('campus-custom-popup');
	if(document.getElementById('campus-custom-popup-close-btn'))
	document.getElementById('campus-custom-popup-close-btn').style.display = showCloseButton?'':'none';

	var overlay = document.getElementById('campus_popup_overlay');

	CMPS_AttachElementToRootBody(overlay);
	CMPS_AttachElementToRootBody(popup);
	popup.style.width = width+'px';
	popup.style.height = height+'px';
	document.getElementById('campus-custom-popup-html').innerHTML = html;
	popup.style.display = '';


	var w = win.width;
	var h = document.body.scrollHeight;
	var BodyHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
	if(h<BodyHeight) {
		h = BodyHeight;
	}
	overlay.style.width='100%';
	overlay.style.height=h+'px';


	CampusHideAllTopControls();

	CMPS_fadeIn(overlay);





	var iebody=(document.compatMode && document.compatMode != "BackCompat")? document.documentElement : document.body;

	var dsoctop=document.all? iebody.scrollTop : pageYOffset;
	var top = (dsoctop+(win.height-height)/2)+'px';
	var left = ((w - width)/2-20)+'px';

	popup.style.top=top;
	popup.style.left=left;
	popup.style.display='block';
	CMPS_fadeIn(popup);

}

function closeCustomModalDialog()
{
	var popup = document.getElementById('campus-custom-popup');
	var overlay = document.getElementById('campus_popup_overlay');
	if(popup)
	popup.style.display='none';
	if(overlay)
	overlay.style.display = 'none';
	CampusRestoreAllTopControls();
}


function closeLoginDialog()
{

	$("campus_popup_overlay").style.display = 'none';
	$("login_box").style.display ='none';
	CampusRestoreAllTopControls();
}


function CMPS_GetDocHeight() {
    var D = document;
    return Math.max(
        Math.max(D.body.scrollHeight, D.documentElement.scrollHeight),
        Math.max(D.body.offsetHeight, D.documentElement.offsetHeight),
        Math.max(D.body.clientHeight, D.documentElement.clientHeight)
    );
}

//
//common login dialog function
function showLoginDialogChoiseSchool(e){
    var popup_id = "register_user_and_choise_school";
    popup
    ({
            popup_id:popup_id,
            speed:100
    });
    
}

function showLoginDialog(e, option, post_type){
    
	jQuery('.login-post-buttons').hide();
	jQuery('.login-post-buttons.default').show();

	var _this = this;

	var processLoginType = function(){
		jQ('.sgn_in_popup_left').css({
			'padding-right': 0,
			'border-right': 'none'
		});
	};

	if(option=='login-required-main' || option=='no-login'){
		processLoginType = function() {
			if (typeof (window.CodeAdapter) !== 'undefined' && window.CodeAdapter.project_type === 'wordpress_plugin') {
				jQ('.sgn_in_popup').width(750);
			}
			jQ('.sgn_in_popup_rigth').show();

			if (post_type && post_type !== 'default') {
				jQuery('.login-post-buttons').hide();
				jQuery('.login-post-buttons.' + post_type).show();

				jQuery('.login-sign-up-link').hide();
				jQuery('.login-sign-up-link.' + post_type).show();
			} else if (option=='no-login') {
				jQ('.sgn_in_popup_left').hide();
				jQ('.sgn_in_popup_rigth').css({
					'margin-left': 0,
				});
			} else {
				//if( typeof(window.CodeAdapter) === 'undefined' || window.CodeAdapter.project_type !== 'wordpress_plugin') {
					jQ('.sgn_in_popup_left').css({
						'padding-right': 0,
						'border-right': 'none'
					});
				//}
			}
		};
		if (typeof ConversionTracking !== typeof undefined) {
			new ConversionTracking().triggerAction({action_id: 17});
		}
	} else if(option == 'need-redirect-after-login'){
        processLoginType = function(){
        	if (typeof redirect_link_after_login !== typeof undefined) {
                jQ("#sgn_in_popup input[name='redirect']").val(redirect_link_after_login);
            }
        }
    }

    if (jQ(e)) {
    	if (jQ(e).attr('redirect-after-login')) {
            processLoginType = function(){

                    jQ("#sgn_in_popup input[name='redirect']").val(jQ(e).attr('redirect-after-login'));

            }
		}
	}

	if( typeof(window.CodeAdapter) !== 'undefined' && window.CodeAdapter.project_type === 'wordpress_plugin') {
		var popup_id = "sgn_in_popup";
		popup
		({
			popup_id: popup_id,
			speed: 100
		});
	} else {
        var callback = "";
        if(typeof jQuery(e).attr("amd-action") !== typeof undefined){
            setCookie('login_callback_amd_action', e.outerHTML, { expires: 0 , path: '/'});
        }

		requirejs(['popup/signIn'], function (sign_in) {
			sign_in.instance.contentLoaded = processLoginType;
			sign_in.instance.open();
		});
	}
}


function showLoginDialog2(e)
{
    
    
	var win = CMPS_GetWindowSize();

	var w = win.width;
	var h = document.body.scrollHeight;
	var BodyHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
	if(h<BodyHeight)
	{
		h = BodyHeight;
	}
	var overlay = $("campus_popup_overlay");
	if(overlay==null)
	{
		overlay = document.createElement('DIV');
		overlay.id = 'campus_popup_overlay';

	}
	CMPS_AttachElementToRootBody(overlay);
	overlay.style.left='0';
	overlay.style.top='0';
	overlay.style.position='absolute';
	overlay.style.width='100%';
	overlay.style.height=CMPS_GetDocHeight()+'px';


	CampusHideAllTopControls();

	CMPS_fadeIn(overlay);
/*
	var captcha = $('captcha_img');
	var unique = Math.round(Math.random()*1000);
	if(captcha)
	captcha.style.backgroundImage = 'url('+AJAXBaseURL+'/confirm.img.php?reload='+unique+AJAXSessionParam+')';
*/
	var iebody=(document.compatMode && document.compatMode != "BackCompat")? document.documentElement : document.body;

	var dsoctop=document.all? iebody.scrollTop : pageYOffset;
	var loginTop = (dsoctop+(BodyHeight-510)/2);

	var loginLeft = ((w -654)/2);

	CMPS_AttachElementToRootBody($("login_box"));
	$("login_box").style.top = loginTop+'px';
	$("login_box").style.left = loginLeft+'px';
	CMPS_fadeIn($("login_box"));

    try
    {


		//init facebook if not already inititalized

	   /* if(window.isFBInitialized==undefined)
	    {
	    	FB.init({appId: $('fb-root').getAttribute('appID') , status: true, cookie: true, xfbml: true});
	    	window.isFBInitialized = true;
	    }*/


		//FACEBOOK handling
		if($('campus-facebook-login-btn') && $('campus-facebook-reg-btn')) //normal site
		{
				$('campus-facebook-reg-btn').onclick = $('campus-facebook-login-btn').onclick =
				 function()
				 {
				 	var unique = Math.round(Math.random()*1000);
				 	window.campusFacebookPopup = window.open(AJAXBaseURL+'/fc.login.php?unique='+unique+AJAXSessionParam,'campusFCPopup','left='+(loginLeft+200)+',top='+(loginTop+100)+',width=420,height=300,resizable=no,menubar=no,scrollbars=no,status=no,location=no');

				 	window.campusFacebookPopupWatchDogTimer = setInterval("if(window.campusFacebookPopup==null||window.campusFacebookPopup.closed){ window.clearInterval(window.campusFacebookPopupWatchDogTimer);if(window.campusApiHideAllPopups)window.campusApiHideAllPopups();Campus_OpenLink(AJAXBaseURL);}",500);
				 }
				 // function()
				 // {
				 	// FB.getLoginStatus(
					 	// function(response)
					 	// {
				          // if (response.session)  //already logged
				          // {

				          	// Campus_SubmitFacebookLogin();
				          // }

					      // else
					        // {
					        	// FB.login(
							        	  // function(response)
							        	  // {
							        	 	// if(response.session.access_token)
							                	// Campus_SubmitFacebookLogin();
							             	// else
							               		// alert('Error! You have not logged in') ;
							              // }, {perms:'email,publish_stream'}
							            // );

					        // }
					 	// });

			    // };
				
		}
		else if($('campus-facebook-login-btn-api') && $('campus-facebook-reg-btn-api'))
		{
			$('campus-facebook-reg-btn-api').onclick = $('campus-facebook-login-btn-api').onclick =
				 function()
				 {
				 	var unique = Math.round(Math.random()*1000);
				 	window.campusFacebookPopup = window.open(AJAXBaseURL+'/fc.login.php?unique='+unique+AJAXSessionParam,'campusFCPopup','left='+(loginLeft+200)+',top='+(loginTop+100)+',width=420,height=300,resizable=no,menubar=no,scrollbars=no,status=no,location=no');

				 	window.campusFacebookPopupWatchDogTimer = setInterval("if(window.campusFacebookPopup==null||window.campusFacebookPopup.closed){ window.clearInterval(window.campusFacebookPopupWatchDogTimer);if(window.campusApiHideAllPopups)window.campusApiHideAllPopups();loadCampusAPIData(campusAPIBaseURL);}",500);
				 }
		}
    }
	catch(err)
	{}

	var event = e || window.event;
	if (event.preventDefault)
	{
		try
		{
			event.preventDefault();
		}
		catch(err){};
	}
	else
	{
		event.returnValue = false;
	}
	return false;
}

function Campus_SubmitFacebookLogin()
{
				$("login_box").style.display ='none';
				var sessionParams = AJAXSessionParam;
				var redirect = $('globalLoginRedirect').value;
				var unique = Math.round(Math.random()*1000);
				var urlReq = AJAXBaseURL+'/external.ajaxapi.php?facebook_login_process=1'+AJAXSessionParam+'&unique='+unique+'&redirect='+redirect;

				dhtmlCampusAveLoadScript(urlReq);

}


function showQuickLoginDialog(email)
{
    if(!email)
	 email = CampusPostingUserEmail;
	var win = CMPS_GetWindowSize();

	var w = win.width;
	var h = document.body.scrollHeight;
	var BodyHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
	if(h<BodyHeight) {
		h = BodyHeight;
	}
	var overlay = $("campus_popup_overlay");
	if(overlay==null)
	{
		overlay = document.createElement('DIV');
		overlay.id = 'campus_popup_overlay';

	}
	overlay.style.left='0';
	overlay.style.top='0';
	overlay.style.position='absolute';
	overlay.style.width='100%';
	overlay.style.height=h+'px';

	CMPS_fadeIn(overlay);

    var iebody=(document.compatMode && document.compatMode != "BackCompat")? document.documentElement : document.body;

	var dsoctop=document.all? iebody.scrollTop : pageYOffset;
	var loginTop = (dsoctop+(BodyHeight-250)/2);

	var loginLeft = ((w -240)/2);
	$("quick-login-user-email").value = email;
	$("quick_login_box").style.top = loginTop+'px';
	$("quick_login_box").style.left = loginLeft+'px';
	CMPS_fadeIn($("quick_login_box"));

	//
 	//init facebook if not already inititalized
    if(window.isFBInitialized==undefined)
    {
    	FB.init({appId: $('fb-root').getAttribute('appID') , status: true, cookie: true, xfbml: true});
    	window.isFBInitialized = true;
    }


	//FACEBOOK handling
	if($('campus-facebook-quick-login-btn'))
	{
		$('campus-facebook-quick-login-btn').onclick =
		 function()
		 {
		 	FB.getLoginStatus(
			 	function(response)
			 	{
		          if (response.session)  //already logged
		             Campus_SubmitFacebookQuickLogin();

			      else
			        {
			        	FB.login(
					        	  function(response)
					        	  {
					        	 	if(response.session.access_token)
					                	Campus_SubmitFacebookQuickLogin();
					             	else
					               		alert('Error! You have not logged in') ;
					              }, {perms:'email'}
					            );

			        }
			 	});

	    };
	}
	else if($('campus-facebook-quick-login-btn-api'))
	{
	  	$('campus-facebook-quick-login-btn-api').onclick =
				 function()
				 {
				 	var unique = Math.round(Math.random()*1000);
				 	window.campusFacebookPopupAPI = window.open(AJAXBaseURL+'/fc.login.php?unique='+unique+AJAXSessionParam,'campusFCPopupAPI','left='+(loginLeft+200)+',top='+(loginTop+100)+',width=420,height=300,resizable=no,menubar=no,scrollbars=no,status=no,location=no');

				 	window.campusFacebookPopupWatchDogTimerAPI = setInterval("if(window.campusFacebookPopupAPI==null||window.campusFacebookPopupAPI.closed){ window.clearInterval(window.campusFacebookPopupWatchDogTimerAPI);closeQuickLoginDialog();}",500);
				 }
	}

	return false;
}


function Campus_SubmitFacebookQuickLogin()
{
				$("login_box").style.display ='none';
				var sessionParams = AJAXSessionParam;
				var redirect = $('globalLoginRedirect').value;
				var unique = Math.round(Math.random()*1000);
				var urlReq = AJAXBaseURL+'/external.ajaxapi.php?facebook_quick_login_process=1'+AJAXSessionParam+'&unique='+unique+'&redirect='+redirect;

				dhtmlCampusAveLoadScript(urlReq);

}

function closeQuickLoginDialog()
{
	$("campus_popup_overlay").style.display = 'none';
	$("quick_login_box").style.display ='none';
}

function Campus_Ajax_Submit_Login(e, _this)
{
    var form = jQ(_this).closest('form[name="loginForm"]');
    var form_id = jQ(form).attr('id');
    
    var containerForPreloader =  form.find('.preload_box');
    var preloader = jQ('<img />', {
            'src' : (typeof(plugin_data) !== 'undefined' && typeof(plugin_data.uloop_base_domain) !== 'undefined' ? plugin_data.uloop_base_domain : '') + '/img/loading.gif',
            'alt'  : 'loading',
            'id'   : 'login-preloader',
            'class' : 'login-preloader',
            'style' : 'margin: 25px 0 0 0px;width: 23px;',
    });

    containerForPreloader.html(preloader);

	var sessionParams = AJAXSessionParam;

	var code = '';
	var user = jQ('input[name="user"]', form).val();
	var pass = escape(jQ('input[name="pass"]', form).val());
	var redirect = encodeURIComponent(escape(jQ('input[name="redirect"]', form).val()));
	var successCallbackFunction = jQ('input[name="success_callback"]', form).val();
	var successCallbackFunctionAfterRedirect = jQ('input[name="success_callback_after_redirect"]', form).val();
	var additionalParam = jQ('input[name="additionalParam"]', form).val();
	var preAjaxFunction = jQ('input[name="pre_ajax"]', form).val();
	var async = true;
	if(jQ('input[name="sync"]', form).val() == 1){
		async = false;
	}
	var ERRORLabel = jQ("#global-login-user-error", form).get(0);
    var ERRORLabelPassword = jQ("#global-login-password-error", form).get(0);

	if(window.location.pathname == '/sign-up' && !redirect.length){
        redirect = '/';
	}

	if(!validationEmail(jQ('input[name="user"]', form).get(0), ERRORLabel)) {
		jQ('#login-preloader', form).remove();
		return false;
	}

	if(!validationInput(jQ('input[name="pass"]', form).get(0), ERRORLabelPassword)) {
		CMPS_SetErrorText_noneScroll(ERRORLabelPassword, ' Please enter password');
		jQ('#login-preloader', form).remove();
		return false;
	}

	var currentDomain = $('globalCurrentDomain') ? $('globalCurrentDomain').value : '';
	if (!currentDomain.length)
		currentDomain = $('currentDomain') ? $('currentDomain').value : '';
            
	var rememberme = jQ('#login-rememberme', form).length && jQ('#login-rememberme', form).get(0).checked ? 1 : 0;
	var unique = Math.round(Math.random() * 1000);
	var AJAXBaseURL = currentDomain.length ? currentDomain : 'http://www.uloop.com';

	var verification = document.getElementsByName('verification')[0].value;

	var comm = document.getElementById('comment_text');
        

	//Professor comment before login
	if(comm && comm.value != 'Add a comment...' && comm.value.length > 0)
	{
		comm = escape(comm.value) + '&prof=' + escape(document.getElementById('prof').value);
	}
	else
	{
		comm = '';
	}





	// var urlReq = AJAXBaseURL+'/ajaxapi.php?login_process=1'+sessionParams+'&contactCheck='+code+'&unique='+unique+'&verification='+verification+'&user='+user+'&pass='+pass+'&rememberme='+rememberme+'&currentDomain='+currentDomain+'&form_id='+form_id+'&redirect='+redirect + '&comm=' + comm;
	//
	// if(typeof(successCallbackFunction) !== 'undefined' && successCallbackFunction.length) {
	// 	urlReq += '&scnf=' + successCallbackFunction;
	// }
	//
	// if(window.campusAPIPartnerID)
	//  urlReq += '&APIJS='+campusAPIPartnerID;
	//
	// if( typeof(window.CodeAdapter) !== 'undefined' && window.CodeAdapter.project_type === 'wordpress_plugin' && typeof(window.CodeAdapter.plugin_param) !== 'undefined'){
	// 	urlReq += '&'+window.CodeAdapter.plugin_param;
	// }
    //
	// dhtmlCampusAveLoadScript(urlReq);

	var urlParam = 'login_process=1'+sessionParams+'&contactCheck='+code+'&unique='+unique+'&verification='+verification+'&user='+user+'&pass='+pass+'&rememberme='+rememberme+'&currentDomain='+currentDomain+'&form_id='+form_id+'&redirect='+redirect + '&comm=' + comm;

	if(typeof(successCallbackFunction) !== 'undefined' && successCallbackFunction.length) {
		urlParam += '&scnf=' + successCallbackFunction;
	}

	if(typeof(additionalParam) !== 'undefined' && additionalParam.length) {
		urlParam += '&additionalParam=' + additionalParam;
	}

	if(typeof(successCallbackFunctionAfterRedirect) !== 'undefined' && successCallbackFunctionAfterRedirect.length) {
		urlParam += '&after_redirect_callback=' + successCallbackFunctionAfterRedirect;
	}

	if( typeof(window.CodeAdapter) !== 'undefined' && window.CodeAdapter.project_type === 'wordpress_plugin' && typeof(window.CodeAdapter.plugin_param) !== 'undefined'){
		urlParam += '&'+window.CodeAdapter.plugin_param;
	}

	if(preAjaxFunction && typeof(window[preAjaxFunction]) == 'function'){
		window[preAjaxFunction]();
	}

    window.CodeAdapter.ajaxRequest({
		url: "ajaxapi.php",
		data: urlParam,
		async: async,
		success: function(data){
			if(window.CodeAdapter.project_type === 'wordpress_plugin') {
				console.log(data);
				eval(data);
			} else {
                if(typeof data == "string" && data.indexOf("window.location.href") !== -1){
                    console.log("popup/loginInfo")
                    jQ('.popup-scrollable-overflow').remove();
                    if (window.CodeAdapter.project_type !== 'wordpress_plugin') {
                        requirejs(['popup/loginInfo'], function (login) {
                            login.instance.open();
                            return false;
                        });
                    }
                }
			}
		}
	});




	var event = e || window.event;
	if (event.preventDefault)
	{
		event.preventDefault();
	}
	else
	{
		event.returnValue = false;
	}
	return false;
}

function Campus_AJAX_Submit_Reg_Student_Form(e)
{

	var sessionParams = AJAXSessionParam;




	Clear_Form_Error('reg-student-email');
	Clear_Form_Error('reg-student-first-name');
	Clear_Form_Error('reg-student-last-name');
	Clear_Form_Error('reg-student-pass');
	Clear_Form_Error('reg-student-conf-pass');
	
	Clear_Form_Error('reg-student-major');
	Clear_Form_Error('reg-student-grad-year');
	Clear_Form_Error('reg-student-major');
		
	Clear_Form_Error('reg-student-domain');
	//Clear_Form_Error('globalRegisterRedirect');

	var email = Get_Input_Value('reg-student-email');
	var first_name = Get_Input_Value('reg-student-first-name');
	var last_name = Get_Input_Value('reg-student-last-name');
	var pass = Get_Input_Value('reg-student-pass');
	var conf_pass = Get_Input_Value('reg-student-conf-pass');

	var grad_year = Get_Input_Value('reg-student-grad-year');
	var major = Get_Input_Value('reg-student-major');

	var describes = Get_Input_Value('sign_up_describes');

	var verification = document.getElementsByName('verification')[0].value;
	if (document.getElementsByName('mobile')[0]) {
		var mobile = document.getElementsByName('mobile')[0].value;
	} else {
		var mobile = '';
	}

	var domain = Get_Input_Value('reg-student-domain');
	//var redirect = Get_Input_Value('globalRegisterRedirect');

	var currentDomain = $('globalCurrentDomain') ? $('globalCurrentDomain').value : '';

	if (!currentDomain.length)
		currentDomain = $('currentDomain') ? $('currentDomain').value : '';

//+'&redirect='+redirect
	var unique = Math.round(Math.random() * 1000);
	var AJAXBaseURL = currentDomain.length ? currentDomain : 'http://www.uloop.com';
	var urlReq = AJAXBaseURL + '/ajaxapi.php?reg_process_student=1' + sessionParams + '&unique=' + unique + '&email=' + email + '&first_name=' + first_name + '&last_name=' + last_name + '&pass=' + pass + '&conf_pass=' + conf_pass + '&major=' + major + '&grad_year=' + grad_year + '&describes=' + describes + '&curDomain=' + currentDomain + '&domain=' + domain + '&verification=' + verification + '&redirect=' + currentDomain;
	if (mobile) {
		urlReq += "&mobile=true";
	}
	dhtmlCampusAveLoadScript(urlReq);

	var event = e || window.event;
	if (event.preventDefault) {
		event.preventDefault();
	}
	else {
		event.returnValue = false;
	}
	return false;

}

function Campus_ShowForgotLink(e)
{
	var overlay = $("campus_popup_overlay");
	CMPS_AttachElementToRootBody(overlay);
	var win = CMPS_GetWindowSize();
	var w = win.width;
	var h = document.body.scrollHeight;

	var BodyHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
	if(h<BodyHeight) {
		h = BodyHeight;
	}

	overlay.style.width =  '100%';
	overlay.style.height = h+'px';

	CMPS_fadeIn(overlay);

        CMPS_AttachElementToRootBody($("forgot_box"));
	$("login_box").style.display = 'none';
	$("forgot_box").style.display = 'block';
	if($('filter_items_per_page'))
	{
		$('filter_items_per_page').style.visibility ='hidden';
	}
	var event = e || window.event;
	if (event.preventDefault)
	{
		event.preventDefault();
	}
	else
	{
		event.returnValue = false;
	}
	return false;

	$("close_btn3").onclick = function(e)
	{
		$("campus_popup_overlay").style.display = 'none';
		$("forgot_box").style.display = 'none';
		if($('filter_items_per_page'))
		{
			$('filter_items_per_page').style.visibility ='visible';
		}

	};
	return false;
};




function loginRegisterCallback(section,redirect,message,control,domain,posting,parrent_control)
{
	if(section == 'login')
	{
		closeBlankWindow();

		if(redirect!=null && redirect.length>0)
		{
			Campus_OpenLink(redirect);
		}
		else
		{
			if(control!=null && control.length>0 && $(control))
			{


                                if(typeof(parrent_control) !== 'undefined' && parrent_control.length){
                                    var control_obj = jQ('#'+parrent_control+' #'+control).get(0);



									var elem_id = control.replace('-error', '').replace('-global', '');

									// console.log('#'+parrent_control+' #'+control);
									// console.log(jQ(control_obj));
									if (jQ('#'+parrent_control+' #'+control).closest('.register-input-wrap').length) {

										control_obj = jQ('#'+parrent_control+' .register-input-wrap #'+control).get(0);

										console.log(control_obj);

										control_obj.style.visibility='visible';
										control_obj.innerHTML = message;
										CMPS_blink(control_obj);


										jQ('.register-input-wrap #login-user, ' +
											'.register-input-wrap #login-pass, ' +
											'.register-input-wrap #login-posting-user, ' +
											'.register-input-wrap #login-posting-pass').keydown(function(){
											jQ(control_obj).css({'visibility' : 'hidden'});
										});
										jQ('.register-input-wrap #login-user, ' +
											'.register-input-wrap #login-pass, ' +
											'.register-input-wrap #login-posting-user, ' +
											'.register-input-wrap #login-posting-pass').change(function(){
											jQ(control_obj).css({'visibility' : 'hidden'});
										});
										jQ('.register-input-wrap #login-user, ' +
											'.register-input-wrap #login-pass, ' +
											'.register-input-wrap #login-posting-user, ' +
											'.register-input-wrap #login-posting-pass').click(function(){
											jQ(control_obj).css({'visibility' : 'hidden'});
										});

									} else {

										CMPS_blink(CMPS_setHTML(control_obj, message), 3, true);
									}
                                    jQ('#'+parrent_control+' #login-user').keypress(function(){ this.innerHTML=""; });
                                    jQ('#'+parrent_control+' #login-pass').keypress(function(){ this.innerHTML=""; });
                                }
                                else{
                                    CMPS_blink(CMPS_setHTML($(control),message),3,true);
                                    $("login-user").onkeypress = new Function('','$("'+control+'").innerHTML="";');
                                    $("login-pass").onkeypress = new Function('','$("'+control+'").innerHTML="";');

									jQ('.register-input-wrap #login-user, ' +
										'.register-input-wrap #login-pass, ' +
										'.register-input-wrap #login-posting-user, ' +
										'.register-input-wrap #login-posting-pass').keydown(function(){
										jQ('#' + control).css({'visibility' : 'hidden'});
									});
									jQ('.register-input-wrap #login-user, ' +
										'.register-input-wrap #login-pass, ' +
										'.register-input-wrap #login-posting-user, ' +
										'.register-input-wrap #login-posting-pass').change(function(){
										jQ('#' + control).css({'visibility' : 'hidden'});
									});
									jQ('.register-input-wrap #login-user, ' +
										'.register-input-wrap #login-pass, ' +
										'.register-input-wrap #login-posting-user, ' +
										'.register-input-wrap #login-posting-pass').click(function(){
										jQ('#' + control).css({'visibility' : 'hidden'});
									});
                                }
			}
		}
        // remove login preloader
        var preloader = jQ('#login-preloader');
        if(preloader.length) preloader.remove();
	}
	if(section == 'registration')
	{
		if(redirect=='success')
		{
                    if( typeof(window.CodeAdapter) !== 'undefined' && window.CodeAdapter.project_type === 'wordpress_plugin' ){
                        
                        var popup_id = "register_user";
                        popup
                        ({
                                popup_id:popup_id,
                                speed:100
                        });
                        
                        setTimeout(function(){
                            window.location = '/' + ( plugin_data.uloop_university_slug && plugin_data.uloop_university_slug !='student' ? plugin_data.uloop_university_slug : '' );
                        }, 3000);
                    }
                    else{
                        if(domain>0){
                            var popup_id = "register_user";
                            popup
                            ({
                                    popup_id:popup_id,
                                    speed:100
                            });
                        }
                        else{
                            //jQ('#register_user_and_choise_school h3').before('<div class="text_succ_reg"><h2>Success, your registration form has been submitted</h2>An email has been sent to you containing a password that you must use to login to publish ads, manage ads and edit your user information</div>')
                            var popup_id = "register_user_and_choise_school";
                            popup
                            ({
                                    popup_id:popup_id,
                                    speed:100
                            });
                        }
                    }
		}
		else
		{
			if(control!=null && control.length>0 && $(control))
			{
                            
				if(!jQ('#'+control).parents('.super_popup').length){
					CMPS_ScrollToElement(CMPS_setHTML($(control)));
				}
				var elem_id = control.replace('-error', '');

				if (jQ('#' + elem_id).closest('.register-input-wrap').length) {
					CMPS_Trigger_Error(elem_id, message);

				} else {
					CMPS_blink(CMPS_setHTML($(control),message));
				}


				// CMPS_blink(CMPS_setHTML($(control),message));
				//$("reg-student-email").onkeypress = new Function('','$("'+control+'").innerHTML="";');
			}
		}
	}

}

function CMPS_AttachElementToRootBody(el)
{
	return el;
	//not used
	var theBody = document.getElementsByTagName('body')[0];

	if(theBody)
	  {
		theBody.appendChild(el);
	  }
	return el;
}


function CampusNumberInputOnKeyPress(e, val,max)
{

	//Fix bug on android (completely remove check)
	var ua = navigator.userAgent.toLowerCase();
	var isAndroid = ua.indexOf("android") > -1;
	if(isAndroid) {
		return true;
	}

	var keynum;
	var keychar;
	var numcheck;

	var maxLength = 255;
	if(max)
	maxLength = max;

	if (window.event)
	{
		keynum = e.keyCode;
	}
	else if (e.which && e.which!=8 && e.which!=9)
	{
		keynum = e.which;
	}
	else
	return true;

	if( keynum>=96 && keynum<=105  )
	{
		keynum = keynum - 48;
	}
	
	keychar = String.fromCharCode(keynum);
	numcheck = /\d|\./;
	var isDigit = numcheck.test(keychar);

	if(keynum == 8 || keynum == 9 || keynum == 37 || keynum == 39 || keynum == 13)
	{
		return true;
	}

	if (e.srcElement && e.srcElement.setSelectionRange && (e.srcElement.selectionEnd-e.srcElement.selectionStart>0)) //some text is selected
	{
		return isDigit && maxLength>val.length;
	}
	else if(document.selection) //for ie
	{

		var selectedText = document.selection.createRange().text;
		var selectedElement = document.selection.createRange().parentElement();
		if(selectedElement == e.srcElement && selectedText!=null && selectedText.length>0)
		return isDigit && maxLength>val.length;
	}
	else
	return isDigit;




	if(val.indexOf(".") >= 0 && keychar=='.')
	return false;

	if (val.indexOf(".") >= 0 && (val.indexOf(".")+3 <= val.length))
	return false;

	if(maxLength<=val.length)
	return false;

	return (keychar=='.' || isDigit);
}


function CampusMaxInputOnKeyPress(e, val,max)
{

	var keynum;
	var keychar;
	var numcheck;

	var maxLength = 255;
	if(max)
	  maxLength = max;

	if (window.event)
	{
		keynum = e.keyCode;
	}
	else if (e.which && e.which!=8 && e.which!=9)
	{
		keynum = e.which;
	}
	else
	  return true;

	keychar = String.fromCharCode(keynum);



	if((keynum == 8 || keynum ==9|| keynum ==37|| keynum ==39|| keynum ==46))
	{
		return true;
	}


	return maxLength>val.length;


}

function CampusFormatPrice(price)
{
	return new Number(price).toFixed(2);
}

function CampusFormatDate(date)
{
	if(! (date instanceof Date))
	date  = new Date(date);

	return (date.getMonth()+1)+'/'+date.getDate()+'/'+date.getFullYear();
}

function CampusGetPhonePart(phone,part)
{
	if(part>=1 && part<=3)
	{
		return phone.substr( (part-1)*3, (part<3?3:4));
	}
	return '';
}

//returns option
function CampusGetSelectedOption(select)
{
	if(select.selectedIndex>=0)
	return select.options[select.selectedIndex];
	else
	return new Option('','',false,false);
}

//return value
function CampusGetSelectedValue(select)
{
	if(select.selectedIndex>=0)
	return select.options[select.selectedIndex].value;
	else
	return '';
}


//select the option with specified value
function CampusSetSelectValue(select,value)
{
	for(i=0;i<select.options.length;i++)
	{
		if(select.options[i].value == value || escape(select.options[i].value) == value)
		{
			select.selectedIndex = i;
			break;
		}
	}
}


//select the radio button with specified value
function CampusSetRadioGroupValue(radioName,value)
{
	var radios = document.getElementsByName(radioName);
	for(i=0;radios!=null && i<radios.length;i++)
	{
		if(radios[i].value == value || escape(radios[i].value) == value)
		{
			radios[i].checked = true;
			break;
		}
	}
}

function CMPS_Int(str)
{
	var res = parseInt(str);
	if(isNaN(res))
	res = 0;
	return res;
}

function CMPS_Float(str)
{
	var res = parseFloat(str);
	if(isNaN(res))
	res = 0.0;
	return res;
}

//
function CMPS_TimestampToStr(timestamp)
{
	timestamp = CMPS_Int(timestamp);
	if(timestamp<10000000000)
	timestamp *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)
	var dd = new Date(CMPS_Int(timestamp));
	var m = dd.getMonth()+1;
	var d = dd.getDate();
	var y = dd.getFullYear();
	return (m>9?'':'0')+m+'/'+(d>9?'':'0')+d+'/'+y;
}

function CMPS_ScrollToElement(theElement)
{

        var selectedPosX = 0;
	var selectedPosY = 0;

	while(theElement != null){
		selectedPosX += theElement.offsetLeft;
		selectedPosY += theElement.offsetTop;
		theElement = theElement.offsetParent;
	}
     
	if( typeof(window.CodeAdapter) !== 'undefined' && window.CodeAdapter.project_type === 'wordpress_plugin' && jQ('#main-menu').get(0) ){
		jQ('html, body').animate({scrollTop:selectedPosY-170}, 100);
	} else if( typeof(window.CodeAdapter) !== 'undefined' && window.CodeAdapter.project_type === 'wordpress_plugin' && jQ('#header').get(0) && jQ('#header').css('position') == 'fixed' ){
		jQ('html, body').animate({scrollTop:selectedPosY-240}, 100);
	} else {
		jQ('html, body').animate({scrollTop:selectedPosY-100}, 100);
	}
	
	return theElement;

	//old
	/*var selectedPosX = 0;
	var selectedPosY = 0;

	while(theElement != null){
		selectedPosX += theElement.offsetLeft;
		selectedPosY += theElement.offsetTop;
		theElement = theElement.offsetParent;
	}

	window.scrollTo(selectedPosX,selectedPosY-200);
	return theElement;*/
}

function CMPS_SetErrorText(elm,txt)
{
	CMPS_setHTML(elm,txt);
	CMPS_ScrollToElement(elm);
	CMPS_blink(elm);
	return elm;
}

function CMPS_SetErrorText_No_Scroll(elm,txt)
{
	CMPS_setHTML(elm,txt);
	CMPS_blink(elm);
	return elm;
}

function CMPS_Trigger_Error(elem_id,txt)
{
    
//        var elm = jQ('#'+elem_id+':not(:disabled)').get(0);
        var elm = jQ('[id="'+elem_id+'"][disabled!="disabled"]').get(0);
        var error_label = jQ('[id="'+elem_id+'-error"][disabled!="disabled"]').get(0);

		jQ(elm).keydown(function(){
			jQ(error_label).css({'visibility' : 'hidden'});
		});
		jQ(elm).change(function(){
			jQ(error_label).css({'visibility' : 'hidden'});
		});
		jQ(elm).click(function(){
			jQ(error_label).css({'visibility' : 'hidden'});
		});

        if (typeof(txt) === "undefined")
        {
            CMPS_blink(error_label);

			// if (elm.addEventListener) {
			// 	elm.addEventListener('keydown', function () {
			// 		error_label.style.visibility = 'hidden';
			// 		this.keydown = null;
			// 	}, false);
			// }
			// else if (elm.attachEvent) {
			// 	elm.attachEvent("onkeydown", function () {
			// 		this.keydown = null;
			// 		error_label.style.visibility = 'hidden';
			// 	});
			// }

        }
        else{
            CMPS_SetErrorText(error_label,txt);
            // addCustomEvent(elem_id, '', 'keydown');
        }
        
        if(typeof(elm) !== "undefined" && elm != null)
            elm.focus();

        return elm;
}

function CMPS_Trigger_Error_No_Scroll(elem_id,txt)
{
	var elm = jQ('[id="'+elem_id+'"][disabled!="disabled"]').get(0);
	var error_label = jQ('[id="'+elem_id+'-error"][disabled!="disabled"]').get(0);

	jQ(elm).keydown(function(){
		jQ(error_label).css({'visibility' : 'hidden'});
	});
	jQ(elm).change(function(){
		jQ(error_label).css({'visibility' : 'hidden'});
	});
	jQ(elm).click(function(){
		jQ(error_label).css({'visibility' : 'hidden'});
	});
	
	if (typeof(txt) === "undefined")
	{
		CMPS_blink(error_label);

		// if (elm.addEventListener) {
		// 	elm.addEventListener('keydown', function () {
		// 		error_label.style.visibility = 'hidden';
		// 		this.keydown = null;
		// 	}, false);
		// }
		// else if (elm.attachEvent) {
		// 	elm.attachEvent("onkeydown", function () {
		// 		this.keydown = null;
		// 		error_label.style.visibility = 'hidden';
		// 	});
		// }

	}
	else{
		CMPS_SetErrorText_No_Scroll(error_label,txt);
		// addCustomEvent(elem_id, '', 'keydown');
	}

	if(typeof(elm) !== "undefined" && elm != null)
		elm.focus();

	return elm;
}

function CMPS_Check_Element(elem_id)
{
        var elm = jQ('[id="'+elem_id+'"][disabled!="disabled"]').get(0);
//	var elm = jQ('#'+elem_id+':not(:disabled)').get(0);
	if( !elm || elm.value.length == 0 || CMPS_Trim(elm.value).length == 0 )
		return false;
	
	return true;
	
}

function CMPS_SetErrorText_noneScroll(elm,txt)
{
	CMPS_setHTML(elm,txt);
	CMPS_blink(elm);
	return elm;
}


Campus_Collection_getSelectedValue = function(c)
{
	for(var i=0;i<c.length;i++)
	if(c[i].checked)
	return c[i].value;
	return null;
}

Campus_PrepareTextPreview = function(t)
{
	return CMPS_NL2BR(CMPS_stripTags(t,''));
}

CMPS_replaceAll = function(elm, search, replace){
	return elm.split(search).join(replace);
}

CMPS_NL2BR = function (elm)
{
	return elm.split(/[\n]{1}/).join('<BR>');
}

Date.arrayToString = function(dates)
{
	var res = '';
	for(var i=0;dates!=null && i<dates.length;i++)
	{
		if(res.length)
		res+=', ';
		res += CampusFormatDate(dates[i]);
	}
	return res;
}

CMPS_fadeIn = function(elm)
{
	elm.style.display = 'block';
	/*var alpha = 0;
	var maxOpacity = arguments.length>=2?arguments[1]:100;
	if(typeof elm.style.opacity == "string")
	elm.style.opacity = '0';
	else
	elm.style.filter = 'alpha(opacity=0)';
	elm.style.display = 'block';

	function alpha_f()
	{
	alpha+=20;
	if(alpha>maxOpacity)
	alpha = maxOpacity;
	if(typeof elm.style.opacity == "string")
	elm.style.opacity = ''+((alpha)/100.0);
	else
	elm.style.filter = 'alpha(opacity=' + alpha + ')';

	if (alpha < maxOpacity)
	{
	setTimeout(alpha_f, 10);
	}
	};
	alpha_f();
	*/
}

CMPS_blink = function(elm, count, timeDelay)
{
	var count  = count || 3;
	var blinks = 0;
	function blink_f()
	{
		blinks++;
		elm.style.visibility = blinks%2==0 && blinks!=count*2?'visible':'hidden';
		if (blinks < count*2)
		{
                    if(timeDelay){
                        if(blinks === 1)
                            setTimeout(blink_f, 450);
                        else
                            setTimeout(blink_f, 200);
                    }
                    else
                         setTimeout(blink_f, 100);
                            
		}
		else
		elm.style.visibility = 'visible';
	};
	blink_f();
	popup_error();
}

CMPS_blink_elem = function(elm, count, timeDelay)
{
	var count  = count || 3;
	var blinks = 0;
	function blink_f()
	{
		blinks++;
		elm.style.opacity = blinks%2==0 && blinks!=count*2?'1':'0.8';
		if (blinks < count*2)
		{
                    if(timeDelay){
                        if(blinks === 1)
                            setTimeout(blink_f, 450);
                        else
                            setTimeout(blink_f, 200);
                    }
                    else
                         setTimeout(blink_f, 100);
                            
		}
		else
		elm.style.opacity = '1';
	};
	blink_f();
}

CMPS_setHTML = function(elm,html)
{
		elm.innerHTML = html;
	elm.style.visibility = 'hidden';
		return elm;
}

CMPS_stripTags  = function(str, allowed_tags) {
	var key = '', allowed = false;
	var matches = [];
	var allowed_array = [];
	var allowed_tag = '';
	var i = 0;
	var k = '';
	var html = '';

	var replacer = function (search, replace, str) {
		return str.split(search).join(replace);
	};

	// Build allowes tags associative array
	if (allowed_tags) {
		allowed_array = allowed_tags.match(/([a-zA-Z0-9]+)/gi);
	}

	str += '';

	// Match tags
	matches = str.match(/(<\/?[\S][^>]*>)/gi);

	// Go through all HTML tags
	for (key in matches) {
		if (isNaN(key)) {
			// IE7 Hack
			continue;
		}

		// Save HTML tag
		html = matches[key].toString();

		// Is tag not in allowed list? Remove from str!
		allowed = false;

		// Go through all allowed tags
		for (k in allowed_array) {
			// Init
			allowed_tag = allowed_array[k];
			i = -1;

			if (i != 0) { i = html.toLowerCase().indexOf('<'+allowed_tag+'>');}
			if (i != 0) { i = html.toLowerCase().indexOf('<'+allowed_tag+' ');}
			if (i != 0) { i = html.toLowerCase().indexOf('</'+allowed_tag)   ;}

			// Determine
			if (i == 0) {
				allowed = true;
				break;
			}
		}

		if (!allowed) {
			str = replacer(html, "", str); // Custom replace. No regexing
		}
	}

	return str;
}

CMPS_hasHTMLTags = function(str)
{
        return (/<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(str));
	//return (str.match(/([\<])([^\>]{1,})*([\>])/i)!=null);
}


CMPS_GetWindowSize = function()
{
	var  myWidth = 0, myHeight = 0;
	if( typeof( window.innerWidth ) == 'number' ) {
		//Non-IE
		myWidth = window.innerWidth;
		myHeight = window.innerHeight;
	} else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
		//IE 6+ in 'standards compliant mode'
		myWidth = document.documentElement.clientWidth;
		myHeight = document.documentElement.clientHeight;
	} else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
		//IE 4 compatible
		myWidth = document.body.clientWidth;
		myHeight = document.body.clientHeight;
	}

	return {'width':myWidth, 'height':myHeight};

}

function Campus_formToURL(form)
{
	var FE = form.elements;
	var res = '';
	var hash = new Array();
	for(var i=0;i<FE.length;i++)
	{
		if((FE[i].value != FE[i].getAttribute('title') || !FE[i].onfocus) && ( !hash[FE[i].name]))
		{
			var val;
			var isArray = false;
			try
			{
				isArray =  FE[FE[i].name].length;
			}
			catch(err){}

			if(FE[i].type=='select-one')
			val = CampusGetSelectedValue(FE[i]);
			else if(isArray)
			{
				val = Campus_Collection_getSelectedValue(FE[FE[i].name]);
			}
			else
			val = FE[i].value;

			res += '&'+FE[i].name+'='+escape(val);
			hash[FE[i].name]  = true;
		}
	}
	return res;
}

function Campus_submitSearchAdsForm(form,url)
{
	url = url + '?search=1'+Campus_formToURL(form);
	Campus_OpenLink(url);
	return false;
}

function Campus_Preview_ShowImage(option){ 
	var main_image = jQ('#'+option.main_image_id);
	var small_images = jQ('#'+option.small_images_id);
        if(main_image && small_images){
            var images = jQ("img", small_images);
            jQ(images).eq(0).css({"opacity": "1", "filter": "alpha(opacity = 100)"});
            jQ('body').on("click", '#'+option.small_images_id+" li", function(){
                    var SelectIMG = jQ('a', this);
                    var newSrc = jQ(SelectIMG).attr('href');
                    
                    if(!newSrc)
                        return false;
                    
                    for(var i = 0;i < images.length; i++){
                            images[i].style.opacity = 0.4;
                            images[i].style.filter = "alpha(opacity = 40)";
                    }
                    
                    jQ(main_image).attr('src', newSrc);
                    jQ('img', SelectIMG).css({"opacity": "1", "filter": "alpha(opacity = 100)"});
                   
                    return false;
	});
    }
}
//galery resize
function galery_resize(option){
    //start_resize == window width start,end_resize == window width end
    if(!option.block_id){
        return false;
    }
    if(!option.start_resize){
        return false;
    }
    if(!option.end_resize){
        return false;
    }

    var resize_block = document.getElementById(option.block_id);
    if(!resize_block){
        return false;
    }

    var block_for_image = resize_block.children[0];
    var img = document.getElementById("big-preview-image-area");

    var block_style = window.getComputedStyle ? getComputedStyle(resize_block,"") : resize_block.currentStyle;
    var block_for_image_style = window.getComputedStyle ? getComputedStyle(block_for_image,"") : block_for_image.currentStyle;

    var proportion = option.default_w - option.default_h;

    function block_height(){
        block_style = window.getComputedStyle ? getComputedStyle(resize_block,"") : resize_block.currentStyle;
        
        var new_width = parseInt(normal_style(block_style.width)) - parseInt(normal_style(block_for_image_style.paddingLeft)) - parseInt(normal_style(block_for_image_style.paddingRight) - parseInt(normal_style(block_for_image_style.borderLeft) - parseInt(normal_style(block_for_image_style.borderRight))));
        block_for_image.style.width = new_width + "px";
        block_for_image.style.height =  new_width - proportion + "px";
        img.style.maxWidth = new_width + "px";

    }
    function normal_style(style){
        if(!style || style == "auto"){
            return 0;
        }
        return style;
    }
    if(parseInt(block_style.width) < option.start_resize && parseInt(block_style.width) > option.end_resize){
        block_height();
    }
    add_event(window,"resize",function(){
        if(parseInt(block_style.width) < option.start_resize && parseInt(block_style.width) > option.end_resize){
            block_height();
        }
    });
}

function getElementsByClass( searchClass, domNode, tagName) {
	if (domNode == null) domNode = document;
	if (tagName == null) tagName = '*';
	var el = new Array();
	var tags = domNode.getElementsByTagName(tagName);
	var tcl = " "+searchClass+" ";
	for(i=0,j=0; i<tags.length; i++) {
		var test = " " + tags[i].className + " ";
		if (test.indexOf(tcl) != -1)
		el[j++] = tags[i];
	}
	return el;
}

function submitForgot() {

	var email = document.getElementById('forgot_email').value;
	emailTest = "^[_\\.0-9a-z-]+@([0-9a-z][0-9a-z_-]+\\.)+[a-z]{2,4}$";
	var regex = new RegExp(emailTest,"i");
	if (!regex.test(email) || !(email.length > 0)) {
		document.getElementById('forgot_error').style.display='inline';
		document.getElementById('forgot_success').style.display='none';
		CMPS_blink(document.getElementById('forgot_error'));
	} else {
		
		var unique = Math.round(Math.random()*1000);
                
		var urlReq = AJAXBaseURL+'/ajaxapi.php?forgot_process=1'+AJAXSessionParam+'&email='+email+'&unique='+unique;

		dhtmlCampusAveLoadScript(urlReq);
                  
	}
	return false;
}

//replaces illegal unicode chars
function CMPS_Replace_Illegal_Chars(strng)
{
	if(typeof strng == "undefined" || strng == null){
		return '';
	}

    strng = strng.split(String.fromCharCode(8226)).join('-');//replace bullets
	strng = strng.split(String.fromCharCode(8220)).join('"');//replace quotes
	strng = strng.split(String.fromCharCode(8221)).join('"');//replace quotes
	strng = strng.split(String.fromCharCode(8217)).join("'");//replace quotes
	strng = strng.split(String.fromCharCode(8216)).join("'");//replace quotes
	strng = strng.split(String.fromCharCode(8211)).join("-");//replace minus
	strng = strng.split(String.fromCharCode(8212)).join("-");//replace minus
	strng = strng.split(String.fromCharCode(8213)).join("-");//replace minus
	strng = strng.split('\t').join(" ");//replace tab
        strng = strng.replace(/\<!(\-{1,})/,'←');
        strng = strng.replace(/\<(\-{1,})/,'←');
        strng = strng.replace(/(\-{1,})\>/,'→');
        strng = strng.replace(/(\-{1,})!\>/,'→');
       
    return strng.replace(/[^a-zA-Z\.\s\*\\\/\,\;\'\&\-_\%\#\$\?\<\>\{\}\@\!\:\=\+\(\)\`" 0-9←→]+/g,'');
}

function  CMPS_Trim(s) 
{
 if(s!=null)
  return s.replace(/^\s+|\s+$/g,"");
 else
  return null; 
}
