function slider(options){
	
    var self = this;
    var index_visible = 0;//global var,visible item (current item)
    var click = true;//global var,true if animate over

    var slider_id = options.slider_id;
    var prev_class =  options.prev_class || "prev";
    var next_class = options.next_class || "next";
    var pagination_class = options.pagination_class || "pagination";

    var main_block = document.getElementById(slider_id);
    var children = main_block.children;

    self.speed = options.speed || 400;
    self.play = options.play || 0;
	self.slider_bottom_padding = options.slider_bottom_padding || 5;

    var timer = null;
    for(var i = 0;i < children.length;i++){
        var class_name =  children[i].className;
        if(class_name == prev_class){
            self.prev = children[i];
            continue;
        }
        if(class_name == next_class){
            self.next = children[i];
            continue;
        }
        if(class_name == "overflow"){
            self.overflow = children[i];
			
            continue;
        }
        if(class_name == pagination_class){
            self.pagination = children[i];
            continue;
        }
    }
    self.wrap = self.overflow.children[0];
    self.items = self.wrap.children;
	
	var overflow_w = slider_height();
	
    for(var i = 0;i < self.items.length;i++){
        self.items[i].style.position = "absolute";
        if(i != 0){
            self.items[i].style.display = "none";
        }
    }

    self.get_visible_item = function(){
        return index_visible;
    }
    self.animate_next = function(next_visible,event){
        self.items[next_visible].style.left = overflow_w + "px";
        self.items[next_visible].style.display = "block";
        animate({
            time:self.speed,
            animate_func:function(progress){
                self.wrap.style.left = (-overflow_w) * progress + "px";
            },
            complete_func:function(){
                self.items[index_visible].style.display = "none";
                self.wrap.style.left = 0;
                self.items[next_visible].style.left = 0;
                index_visible = next_visible;
                start_timer(event);
                click = true;

            }
        });
    }
    self.animate_prev = function(next_visible,event){
        self.wrap.style.left = -overflow_w + "px";
        self.items[index_visible].style.left = overflow_w + "px";
        self.items[next_visible].style.left = 0;
        self.items[next_visible].style.display = "block";
        animate({
            time:self.speed,
            animate_func:function(progress){
                self.wrap.style.left = (-overflow_w) * reverse_progress(progress) + "px";
            },
            complete_func:function(){
                self.items[index_visible].style.display = "none";
                index_visible = next_visible;
                start_timer(event);
                click = true;
            }
        });

    }
    self.set_pag_active = function(next_visible){
        self.pag_item[index_visible].className = "";
        self.pag_item[next_visible].className = "active";
    }
	self.resize = function(){
		overflow_w = slider_height();
	}
	self.setSliderBottomPadding = function(new_padding){
		self.slider_bottom_padding = new_padding;
	}
    function move_next(event){
        if(click){
            click = false;
            clear_timer(event);
            var next_visible = index_visible + 1;
            if(next_visible >= self.items.length){
                next_visible = 0;
            }
            if(self.pagination){
                self.set_pag_active(next_visible);
            }
            self.animate_next(next_visible,event);
        }
    }
    function move_prev(event){
        if(click){
            click = false;
            clear_timer(event);
            var next_visible = index_visible - 1;
            if(next_visible < 0){
                next_visible = self.items.length - 1;
            }
            if(self.pagination){
                self.set_pag_active(next_visible);
            }
            self.animate_prev(next_visible,event);
        }
    }
    function move_to(event){
        if(click){
            var target = event && event.target || event.srcElement;
            if (target.nodeName != 'LI'){
                return;
            }
            clear_timer(event);
            click = false;
            self.pag_item[index_visible].className = "";
            target.className = "active";
            for(var i = 0;i < self.pag_item.length;i++){

                if(self.pag_item[i] == target){
                    var clicked_index = i;
                    break;
                }
            }

            if(clicked_index != index_visible){
                if(clicked_index < index_visible){
                    self.animate_prev(clicked_index,event);
                }
                if(clicked_index > index_visible){
                    self.animate_next(clicked_index,event);
                }
            }
        }
        return false;
    }
	function start_timer(event){
        if(event && timer){
            timer = setInterval(move_next,self.play);
        }
    }
    function clear_timer(event){
        if(event && timer){
            clearInterval(timer);
        }
    }
	function slider_height(){
		var overflow_comp_style = window.getComputedStyle ? getComputedStyle(self.overflow,"") : self.overflow.currentStyle;
		var overflow_w = overflow_comp_style.width;
		overflow_w = parseFloat(overflow_w);
		self.wrap.style.width = overflow_w * 2 + "px";
		
		for(var i = 0;i < self.items.length;i++){
			self.items[i].style.width = overflow_w + "px";
		}
		return overflow_w;
	}
    if(self.next){
        add_event(self.next,"click",move_next);
    }
    if(self.prev){
        add_event(self.prev,"click",move_prev);
    }
    if(self.play){
        timer = setInterval(move_next,self.play);
    }
    if(self.pagination){
        self.pag_item = self.pagination.children;
        self.pagination.children[index_visible].className = "active";
        add_event(self.pagination,"click",move_to);
    }
	add_event(window,"resize",self.resize);
};