function tabs(options){
    var self = this;
    var active_tab = 0;
    var active_tab_found = false;
    self.tab_menu = document.getElementById(options.tab_menu);
    self.tabs_container = document.getElementById(options.tabs_container);
    if(!self.tab_menu || !self.tabs_container){
        return;
    }
    self.item_class = options.item_class || "item";

    self.tab_menu_item = [];
    for(var i = 0;i < self.tab_menu.children.length;i++){
        self.tab_menu_item.push(self.tab_menu.children[i]);
        if(!active_tab_found && self.tab_menu.children[i].className == 'no-active'){
            active_tab++;
        } else if(self.tab_menu.children[i].className == 'active') {
            active_tab = i;
            active_tab_found = true;
        }/* else {
            active_tab_found = true;
        }*/
    }


    if(!active_tab_found){
        active_tab = 0;
    }

    self.item = [];
    for(var i = 0;i < self.tabs_container.children.length;i++){
        if(self.tabs_container.children[i].className == self.item_class){
            self.item.push(self.tabs_container.children[i]);
        }
    }

    for(var u = 0;u < self.item.length;u++){
        if(u != active_tab){
            self.item[u].style.display = "none";
        }
		else
		{
			self.item[u].style.display = "";
		}
    }
    self.tab_menu_item[active_tab].className = "active";
    function toggle(event){

        var target = event && event.target || event.srcElement;
        while(target.nodeName != "UL"){
            if(target.nodeName == 'LI'){
                if(target.className != "active" && target.className != "no-active"){
                    var new_active = 0;
                    self.tab_menu_item[active_tab].className = "";
                    target.className = "active";
                    for(var i = 0;i < self.tab_menu_item.length;i++){
                        if(self.tab_menu_item[i].className == "active"){
                            new_active = i;
                            break;
                        }
                    }

                    self.item[active_tab].style.display = "none";
                    self.item[new_active].style.display = "block";
                    active_tab = new_active;
                }
                break;
            }
            target = target.parentNode;
        }
        return false;
    }
    add_event(self.tab_menu,"click",toggle);
}
